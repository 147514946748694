<template>
	<div>
		<ContentHeader title="Analisis Demografi" subTitle="Analisis Usia" url="analisis-demografi" />
		<div class="content-header">
			<div class="container-fluid">
				<div class="card">
          <div class="card-header">
            <h3 class="card-title">Data Statistik</h3>
          </div>
          <div class="card-body" style="display: block;">
          	<div class="row" v-if="toogleCabang">
          		<div class="col-lg-4">
          			<label>Pilih Cabang</label>
          			<multiselect 
				          v-model="cabang_select_value"
				          deselect-label="Can't remove this value"
				          track-by="nama"
				          label="nama"
				          placeholder=""
				          :options="cabang"
				          :loading="loading_cabang"
				          :allow-empty="false"
				        >
				        </multiselect>
          		</div>
          	</div>
            <div class="row align-items-center mt-2">
              <div class="col-lg-2">
                <span>Jenis Periode</span>
                <select class="custom-select form-control" v-model="jenis_periode">
                  <option value="tanggal">Tanggal</option>
                  <option value="bulan">Bulan</option>
                  <option value="tahun">Tahun</option>
                </select>
              </div>
              <div class="col-lg-2">
              	<span>Tanggal</span>
              	<input type="date" name="" v-model="tanggal" class="form-control">
              </div>
              <div class="col-lg-2">
                <button class="btn btn-primary mt-4" @click="getDataResult">Tampilkan</button>
              </div>
              <div class="col-lg-2">
              </div>
            </div>
            <!-- Chart -->
            <v-chart class="chart" :option="option" v-if="toggle_chart" />
            <!-- End Chart -->
            <p class="text-center mt-2" v-if="memuat_data">Memuat Data...</p>
            <p class="text-center mt-2">{{ cek_data }}</p>
          </div>
        </div>
			</div>
		</div>
	</div>
</template>

<script>
import ContentHeader from '@/components/ContentHeader2'
import Multiselect from '@suadelabs/vue3-multiselect'

import { ref, computed, onMounted } from 'vue'
import store from '@/store'
import axios from 'axios'

import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent
} from "echarts/components";
import VChart from "vue-echarts";

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent
]);

export default{
  components: {
    ContentHeader,
    Multiselect,
    VChart
  },
  setup(){
    const user = computed(() => store.getters['auth/user'])
    const cabang_store = computed(() => store.getters['auth/cabang'])
    const memuat_data = ref(false)
    const cek_data = ref('')
    const tanggal = ref('')
    const jenis_periode = ref('')
    const toggle_chart = ref(false)

    const getDataResult = async () => {
      memuat_data.value = true
      toggle_chart.value = false
      cek_data.value = ''
      let cabang_id = ''

      if (cabang_select_value.value == '') {
        cabang_id = user.value.cabang_id
      } else {
        cabang_id = cabang_select_value.value.id
      }

      if (tanggal.value == '' && jenis_periode.value == '') {
        memuat_data.value = false
        cek_data.value = 'Pilih Tanggal dan Jenis Periode'
      } else {
        let { data } = await axios.get(`api/analisis_statistik/usia/${cabang_id}/${tanggal.value}/${jenis_periode.value}`)
        console.log(data)
        if (data != 'kosong') {
          memuat_data.value = false
          cek_data.value = ''
          option.value.series[0].data[0].value = data.data.tradisional
          option.value.series[0].data[1].value = data.data.baby_boomers
          option.value.series[0].data[2].value = data.data.generasi_x
          option.value.series[0].data[3].value = data.data.generasi_millenial
          option.value.series[0].data[4].value = data.data.tradisional
          option.value.series[0].data[5].value = data.data.tradisional
          toggle_chart.value = true
        }
      }

    }

		const toogleCabang = ref(false)
		const cabang_select_value = ref('')
		const cabang = ref([])
		const loading_cabang = ref(true)
		const getCabang = async () => {
      if (cabang_store.value.jenis == 'pusat') {
        toogleCabang.value = true
        toggle_chart.value = false
        let { data } = await axios.get(`api/cabang/getWhereCabang/${cabang_store.value.id}`)
        
        if (data != 'kosong') {
          cabang.value = data
          loading_cabang.value = false
        } else {
          cabang.value = []
          loading_cabang.value = false
        }
      } else {
        getDataResult()
      }
		}


    const option = ref({
      title: {
        text: "Statistik Usia",
        left: "center"
      },
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b} : {c} Pasien ({d}%)"
      },
      series: [
        {
          name: "Usia",
          type: "pie",
          radius: "60%",
          center: ["50%", "50%"],
          data: [
            { value: 0, name: "Umur 76 Tahun ke atas (Tradisional)" },
            { value: 0, name: "Umur 57 Tahun - 75 Tahun (Baby Boomers)" },
            { value: 0, name: "Umur 41 Tahun - 56 Tahun (Generasi X)" },
            { value: 0, name: "Umur 27 Tahun - 40 Tahun (Generasi Millenial)" },
            { value: 0, name: "Umur 11 Tahun - 26 Tahun (Generasi Z)" },
            { value: 0, name: "Umur 1 Tahun - 10 Tahun (Generasi Alpha)" },
          ],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)"
            }
          }
        }
      ]
    })
	

    onMounted(() => {
    	getCabang()
    })

    return {
      tanggal, cabang_select_value, cabang, loading_cabang, toogleCabang, option, jenis_periode, memuat_data, cek_data, getDataResult, toggle_chart
    }
  }
}
</script>

<style scoped>
.chart {
  height: 400px;
  margin-top: 40px;
}
</style>